import request from '@/utils/request'
// const baseUrl = 'https://erptest.renkangshangcheng.cn/erp/'
// const baseUrl = 'http://39.107.120.71:9527/erp'
// 获取采购单详情
export function getPurchaseInfo(data) {
  return request({
    url: 'backend/purchase/getInfo',
    method: 'post',
    params: data
  })
}
// 获取提货单详情
export function getApproveInfo(data) {
  return request({
    url: 'backend/pickup/info',
    method: 'post',
    params: data
  })
}
// 通用审批
export function updateBill(data) {
  return request({
    url: 'approval/modifyV2',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 库存调拨审批
export function updateBillAll(data) {
  return request({
    url: 'backend/allot/check',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 出货或拒绝
export function updateBillNew(data) {
  return request({
    url: 'backend/pickup/modify',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 获取提货单流程
export function getApprovalPickup(data) {
  return request({
    url: 'approval/getApprovalInfoV2/' + data.id,
    method: 'get',
    data
  })
}
// 根据采购订单号 获取流程
export function getApproval(data) {
  return request({
    url: 'approval/getApproval/' + data.id,
    method: 'get',
    data,
    headers: { 'Content-Type': 'application/json' },
  })
}
// 审批采购单
export function purchaseApprove(data) {
  return request({
    url: 'backend/purchase/purchaseApprove',
    method: 'post',
    data,
  })
}
// 获取密钥
export function getCosToken(data) {
  return request({
    url: 'backend/bucket/getUrl',
    method: 'post',
    params: data
  })
}
// 获取卡转账详情
export function getCardInfo(data) {
  return request({
    url: 'backend/refund/getRefundOrderInfo',
    method: 'post',
    params: data
  })
}
// 获取流程
export function getApprovalV2(data) {
  return request({
    url: 'approval/getApprovalInfoV2/' + data.id,
    method: 'get',
    data,
    dataType: 'json'
  })
}
// 退货申请通过
export function passReturnOrder(data) {
  return request({
    url: 'backend/refund/adopt',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 退货驳回
export function rejectReturnOrder(data) {
  return request({
    url: 'backend/refund/reject',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 卡转账详情
export function getCardTransferInfo(data) {
  return request({
    url: 'backend/card/account/info/' + data.id,
    method: 'get',
    data,
  })
}
// 提交审批
export function toApproval(data) {
  return request({
    url: 'approval/modifyFlow',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 获取用户的卡
export function getCardByUser(data) {
  return request({
    url: 'backend/refund/getCardListByUserid',
    method: 'post',
    data
  })
}
// 获取通知列表
export function getNoticeList(data) {
  return request({
    url: 'notice-flow/noticeList',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 查询调拨商品明细
export function getAllotDetail(data) {
  return request({
    url: '/backend/allot/details/' + data.id,
    method: 'get',
    data,
    dataType: 'json'
  })
}
// 调拨审核
export function examineAllot(data) {
  return request({
    url: '/backend/allot/check',
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 获取换货数据
export function getExchangeInfo(data) {
  return request({
    url: '/front/exchange/getInfo?exchangeNo='+data.exchangeNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 换货通过
export function exchangeOk(data) {
  return request({
    url: '/backend/exchange/exchangeAdopt?exchangeNo='+data.exchangeNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 换货拒绝
export function exchangeNo(data) {
  return request({
    url: '/backend/exchange/exchangeNo?exchangeNo='+data.exchangeNo+'&exchangeMsg='+data.exchangeMsg,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 获取价格范围审批详情
export function getFixPriceApprovalInfo(data) {
  return request({
    url: '/goods-price/getFixPriceApprovalInfo?batchNo='+data.batchNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 价格范围通过
export function adoptPrice(data) {
  return request({
    url: '/goods-price/adoptPrice?batchNo='+data.batchNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 价格范围拒绝
export function rejectPrice(data) {
  return request({
    url: '/goods-price/rejectPrice?batchNo='+data.batchNo+'&rejectMsg='+data.rejectMsg,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 获取策略审批详情
export function getApprovalMaketPolicy(data) {
  return request({
    url: '/marketPolicy/getApprovalMaketPolicy?policyId='+data.batchNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 策略通过
export function adoptMarketPolicy(data) {
  return request({
    url: '/marketPolicy/adoptMarketPolicy?policyId='+data.batchNo,
    method: 'post',
    data,
    dataType: 'json'
  })
}
// 策略拒绝
export function rejectMarketPolicy(data) {
  return request({
    url: '/marketPolicy/rejectMarketPolicy?policyId='+data.batchNo+'&rejectMsg='+data.rejectMsg,
    method: 'post',
    data,
    dataType: 'json'
  })
}
